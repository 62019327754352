import { makeAutoObservable, runInAction } from "mobx";
import { instance } from "../api";
import { checkError, isError } from "../api/helpers";
import { IDislocations, IFio, IGetReportXLSX, IGoCountGroup, IGoCountRaport, IGoExternalObject, IGoRaport, IJournalActionsList, IRaportAlertList, IRaportList, IRaportMonthList, IRaportMonthNewList, IRaportOneMonth, IRaportVKList, IReportFilters } from "./types";
import {IAlarmKindItem, TEnumItem} from "../../components/helpers/utils";

export class Reports {


    //data
    goCountRaport: IGoCountRaport | null = null
    goCountRaportLoading: boolean = false

    fioData: any[] | null = null
    fioLoading: boolean = false

    goExternalObject: IGoExternalObject | null = null
    goExternalObjectLoading: boolean = false

    goCountGroup: IGoCountGroup | null = null
    goCountGroupLoading: boolean = false

    //написать новый горапорт
    goRaport: IGoRaport | null = null
    goRaportLoading: boolean = false

    newGoRaport: any
    newGoRaportLoading: boolean = false

    dislocations: IDislocations | null = null
    dislocationsLoading: boolean = false

    gbrList: TEnumItem[] | any[] = []
    gbrListLoading: boolean = false

    pcoList: TEnumItem[] = []
    pcoListLoading: boolean = false

    downloadExcelLoading: boolean = false

    newReport: IRaportList | null | any = null
    newReportLoading: boolean = false

    raportVK: IRaportVKList | null | any = null
    raportVKLoading: boolean= false

    raportAlert: IRaportAlertList | null | any = null
    raportAlertLoading: boolean= false

    journalActions: IJournalActionsList | null | any = null
    journalActionsLoading: boolean = false

    raportMonth: IRaportMonthList | null | any = null
    raportMonthLoading: boolean = false

    raportMonthNew: IRaportMonthNewList | null | any = null
    raportMonthNewLoading: boolean = false

    operatorRaport: any
    operatorRaportLoading: boolean = false

    raportOneMonth: IRaportOneMonth[] | null = null

    eventTypes: TEnumItem[] | null = null
    eventTypesLoading: boolean = false

    alarmKindTypes: IAlarmKindItem[] | null = null
    alarmKindTypesLoading: boolean = false

    caesarReports: any = null
    caesarReportsLoadnig: boolean = false

    chopReports: any = null
    chopReportsLoadnig: boolean = false

    chop: any = null
    chopLoading: boolean = false

    addressOfRaports: any = null
    addressOfRaportsLoading: boolean = false

    objectsOfRaports: any = null
    objectsOfRaportsLoading: boolean = false

    pultsOfRaports: any = null
    pultsOfRaportsLoading: boolean = false

    settings: any =  null
    settingsLoading: boolean = false

    putSettings: any = null
    putSettingsLoading: boolean = false

    postSettings: any = null
    postSettingsLoading: boolean = false

    deleteSettings: any = null
    deleteSettingsLoading: boolean = false

    ExternalDepartureGeneralCountReport: any = null
    ExternalDepartureGeneralCountReportLoading: boolean = false
    //data




    constructor() {
        makeAutoObservable(this)
    }

    getGoCountRaport = async (data: IReportFilters) => {
        runInAction(() => this.goCountRaportLoading = true);
        await instance().close().getGoCountRaport(data).then(res => {
            this.goCountRaport = res.data?.response;
            this.goCountRaportLoading = false;
        }).catch(err => {
            checkError(err);
            this.goCountRaportLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.goCountRaportLoading = false);
        })
    }

    getFIO = async () => {
        runInAction(() => this.fioLoading = true)
        await instance().close().getFio().then(res => {
            this.fioData = res.data?.response;
            this.fioLoading = false
        }).catch(err => {
            checkError(err);
            this.fioLoading = false
            isError(err)
        }).finally(() => {
            runInAction(() => this.fioLoading = false)
        })
    }

    getGoExternalObject = async (data: IReportFilters) => {
        runInAction(() => this.goExternalObjectLoading = true);
        await instance().close().getGoExternalObject(data).then(res => {
            this.goExternalObject = res.data?.response;
            this.goExternalObjectLoading = false;
        }).catch(err => {
            checkError(err);
            this.goExternalObjectLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.goExternalObjectLoading = false);
        })
    }

    getGoCountGroup = async (data: IReportFilters) => {
        runInAction(() => this.goCountGroupLoading = true);
        await instance().close().getGoCountGroup(data).then(res => {
            this.goCountGroup = res.data?.response;
            this.goCountGroupLoading = false;
        }).catch(err => {
            checkError(err);
            this.goCountGroupLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.goCountGroupLoading = false);
        })
    }

    getGoRaport = async (data: IReportFilters) => {
        runInAction(() => this.goRaportLoading = true);
        await instance().close().getGoRaport(data).then(res => {
            this.goRaport = res.data?.response;
            this.goRaportLoading = false;
        }).catch(err => {
            checkError(err);
            this.goRaportLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.goRaportLoading = false);
        })
    }

    getDislocations = async (data: IReportFilters) => {
        runInAction(() => this.dislocationsLoading = true);
        await instance().close().getDislocations(data).then(res => {
            this.dislocations = res.data?.response;
            this.dislocationsLoading = false;
        }).catch(err => {
            checkError(err);
            this.dislocationsLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.dislocationsLoading = false);
        })
    }

    getNewReport = async (data: IReportFilters) => {
        runInAction(() => this.newReportLoading = true);
        await instance().close().getRaports(data).then(res => {
            this.newReport = res.data?.response;
            this.newReportLoading = false;
        }).catch(err => {
            checkError(err);
            this.newReportLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.newReportLoading = false);
        })
    }

    getNewGoReport = async (data: IReportFilters) => {
        runInAction(() => this.newGoRaportLoading = true);
        await instance().close().getNewGoRaports(data).then(res => {
            this.newGoRaport = res.data?.response;
            this.newGoRaportLoading = false;
        }).catch(err => {
            checkError(err);
            this.newGoRaportLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.newGoRaportLoading = false);
        })
    }

    getJournalActions = async (data: IReportFilters) => {
        runInAction(() => this.journalActionsLoading = true);
        await instance().close().getJournalActions(data).then(res => {
            this.journalActions = res.data?.response;
            this.journalActionsLoading = false;
        }).catch(err => {
            checkError(err);
            this.journalActionsLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.journalActionsLoading = false);
        })
    }

    getReportVK = async (data: IReportFilters ) => {
        runInAction(() => this.raportVKLoading = true);
        await instance().close().getRaportsVK(data).then(res => {
            this.raportVK = res.data?.response;
            this.raportVKLoading = false;
        }).catch(err => {
            checkError(err);
            this.raportVKLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.raportVKLoading = false);
        })
    }

    getReportMonth = async (data: IReportFilters ) => {
        runInAction(() => this.raportMonthLoading = true);
        await instance().close().getRaportsMonth(data).then(res => {
            this.raportMonth = res.data?.response;
            this.raportOneMonth = res.data?.response;
            this.raportMonthLoading = false;
        }).catch(err => {
            checkError(err);
            this.raportMonthLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.raportMonthLoading = false);
        })
    }

    getReportOperator = async (data: IReportFilters ) => {
        runInAction(() => this.operatorRaportLoading = true);
        await instance().close().getOperatorRaport(data).then(res => {
            this.operatorRaport = res.data?.response;
            this.operatorRaportLoading = false;
        }).catch(err => {
            checkError(err);
            this.operatorRaportLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.operatorRaportLoading = false);
        })
    }

    getReportMonthNew = async (data: IReportFilters ) => {
        runInAction(() => this.raportMonthNewLoading = true);
        await instance().close().getRaportsMonthNew(data).then(res => {
            this.raportMonthNew = res.data?.response;
            this.raportMonthNewLoading = false;
        }).catch(err => {
            checkError(err);
            this.raportMonthNewLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.raportMonthNewLoading = false);
        })
    }

    getReportAlert = async (data: IReportFilters ) => {
        runInAction(() => this.raportAlertLoading = true);
        await instance().close().getRaportsAlert(data).then(res => {
            this.raportAlert = res.data?.response;
            this.raportAlertLoading = false;
        }).catch(err => {
            checkError(err);
            this.raportAlertLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.raportAlertLoading = false);
        })
    }

    getExternalDepartureGeneralCountReport = async (data: IReportFilters) => {
        runInAction(() => this.ExternalDepartureGeneralCountReportLoading = true);
        await instance().close().getExternalDepartureGeneralCountReport(data).then(res => {
            this.ExternalDepartureGeneralCountReport = res.data?.response;
            this.ExternalDepartureGeneralCountReportLoading = false;
        }).catch(err => {
            checkError(err);
            this.ExternalDepartureGeneralCountReportLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.ExternalDepartureGeneralCountReportLoading = false);
        })
    }

    getCaesarReport = async (data: IReportFilters) => {
        runInAction(() => this.caesarReportsLoadnig = true);
        await instance().close().getCaesarRaport(data).then(res => {
            this.caesarReports = res.data?.response;
            this.caesarReportsLoadnig = false;
        }).catch(err => {
            this.caesarReportsLoadnig = false
            isError(err)
        }).finally(() => {
            runInAction(() => this.caesarReportsLoadnig = false);
        })
    }

    getGBRS = async () => {
        runInAction(() => this.dislocationsLoading = true);
        await instance().close().getGBRS().then(res => {
            this.gbrList = res.data?.response || [];
            this.gbrListLoading = false;
        }).catch(err => {
            checkError(err);
            this.dislocationsLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.dislocationsLoading = false);
        })
    }

    getPCO = async () => {
        runInAction(() => this.pcoListLoading = true);
        await instance().close().getPCO().then(res => {
            this.pcoList = res.data?.response || [];
            this.pcoListLoading = false;
        }).catch(err => {
            checkError(err);
            this.dislocationsLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.dislocationsLoading = false);
        })
    }

    getEventTypes = async () => {
        runInAction(() => this.eventTypesLoading = true);
        await instance().close().getEventTypes().then(res => {
            this.eventTypes = res.data?.response?.types || [];
            this.eventTypesLoading = false;
        }).catch(err => {
            checkError(err);
            this.eventTypesLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.eventTypesLoading = false);
        })
    }

    getAlarmKindTypes = async () => {
        runInAction(() => this.alarmKindTypesLoading = true);
        await instance().close().getAlarmKindTypes().then(res => {
            this.alarmKindTypes = res.data?.response || [];
            this.alarmKindTypesLoading = false;
        }).catch(err => {
            checkError(err);
            this.alarmKindTypesLoading = false;
            isError(err)
        }).finally(() => {
            runInAction(() => this.alarmKindTypesLoading = false);
        })
    }

    getReportXlSX = async (data: IGetReportXLSX) => {
        await instance().close().getReportXLSX(data).then(res => {
            runInAction(() => this.downloadExcelLoading = true)
            var a = document.createElement("a"); //Create <a>
            a.href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + res.data; //Image Base64 Goes here
            a.download = data?.reportName + '.' + data?.fileExtension; //File name Here
            a.click();
        }).catch(err => {
            checkError(err);
            runInAction(() => this.downloadExcelLoading = false)
            isError(err)
        }).finally(() => {
            runInAction(() => this.dislocationsLoading = false);
        })

    }

    getReportChops = async () => {
        await instance().close().getChops().then((res) => {
            const data = res.data?.response
            const newData = data.map((element: string, index: number) => { return { id: index, name: element } })
            this.chopReports = newData;
            this.chopReportsLoadnig = false;
        }).catch(err => {
            this.chopReportsLoadnig = false
            isError(err)
        }).finally(() => {
            runInAction(() => this.chopReportsLoadnig = false);
        })
    }

    sendReportChops = async (data: IReportFilters) => {
        runInAction(() => this.chopLoading = true);
        await instance().close().sendChops(data).then((res) => {
            const data = res.data.response
            this.chop = data
            this.chopLoading = false;
        }).catch(err => {
            this.chopLoading = false
            isError(err)
        }).finally(() => {
            runInAction(() => this.chopLoading = false);
        })
    }

    getAddressesOfRaports = async (body : {searchValue : string}) => {
        runInAction(() => this.addressOfRaportsLoading = true);
        await instance().close().getAddressesOfRaports(body).then((res) => {
            const data = res.data.response
            this.addressOfRaports = data
            this.addressOfRaportsLoading = false;
        }).catch(err => {
            this.addressOfRaportsLoading = false
            isError(err)
        }).finally(() => {
            runInAction(() => this.addressOfRaportsLoading = false);
        })
    }

    getObjectsOfRaports = async (body : {searchValue : string}) => {
        runInAction(() => this.objectsOfRaportsLoading = true);
        await instance().close().getObjectsOfRaports(body).then((res) => {
            const data = res.data.response
            this.objectsOfRaports = data
            this.objectsOfRaportsLoading = false;
        }).catch(err => {
            this.objectsOfRaportsLoading = false
            isError(err)
        }).finally(() => {
            runInAction(() => this.objectsOfRaportsLoading = false);
        })
    }

    getPultsOfRaports = async (body : {searchValue : string}) => {
        runInAction(() => this.pultsOfRaportsLoading = true);
        await instance().close().getPultsOfRaports(body).then((res) => {
            const data = res.data.response
            this.pultsOfRaports = data
            this.pultsOfRaportsLoading = false;
        }).catch(err => {
            this.pultsOfRaportsLoading = false
            isError(err)
        }).finally(() => {
            runInAction(() => this.pultsOfRaportsLoading = false);
        })
    }
    getSettings = async (flag: any, data: any) => {
        switch(flag) {
            case 'get':
                runInAction(() => this.settingsLoading = true);
                await instance().close().getSettings(data).then(res => {
                    this.settings = res?.data?.response || [];
                    this.settingsLoading = false;
                }).catch(err => {
                    checkError(err);
                    this.settingsLoading = false;
                    isError(err)
                }).finally(() => {
                    runInAction(() => this.settingsLoading = false);
                })
            return this.settings
            
            case 'post':
                runInAction(() => this.postSettingsLoading = true);
                await instance().close().postSettings(data).then(res => {
                    this.postSettings = res.data?.response || [];
                    this.postSettingsLoading = false;
                }).catch(err => {
                    checkError(err);
                    this.postSettingsLoading = false;
                    isError(err)
                }).finally(() => {
                    runInAction(() => this.postSettingsLoading = false);
                })
                break;
            case 'put':
                runInAction(() => this.putSettingsLoading = true);
                await instance().close().putSettings(data).then(res => {
                    this.putSettings = res.data?.response || [];
                    this.putSettingsLoading = false;
                }).catch(err => {
                    checkError(err);
                    this.putSettingsLoading = false;
                    isError(err)
                }).finally(() => {
                    runInAction(() => this.putSettingsLoading = false);
                })
                break;
            case 'delete':
                runInAction(() => this.deleteSettingsLoading = true);
                await instance().close().deleteSettings(data).then(res => {
                    this.deleteSettings = res.data?.response || [];
                    this.deleteSettingsLoading = false;
                }).catch(err => {
                    checkError(err);
                    this.deleteSettingsLoading = false;
                    isError(err)
                }).finally(() => {
                    runInAction(() => this.deleteSettingsLoading = false);
                })
                break;

                default:
                    break;
        }

    }
}




export const ReportsStore = new Reports();