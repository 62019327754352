import React, { FC } from "react";
import { IModal } from "../../../store/sidebar/types.data";
import { IRaport } from "../../../store/reports/types";
import { Dialog } from "@material-ui/core";
import { ModalHeader } from "../../common/Header/ModalHeader/ModalHeader";
import { formatIsoToDate, formatIsoToTime } from "../../helpers/utils";
import { SimpleCheckbox } from "../../common/CheckBox/CheckBox";
import { Button, Table } from "antd";

const s = require('./style.module.scss')

interface IInfoModal extends IModal {
    i: IRaport | null
    arrData : any
}

export const InfoModal: FC<IInfoModal> = ({
    closeHandler,
    i,
    open,
    title,
    arrData
}) => {


    const item = arrData.find((el: { id: any; })  => el.id === i?.key || i?.id)

    const time = item?.time_work

    let utcDate;
    if (time) {
        utcDate = new Date(time);
    } else {
        utcDate = new Date(); 
    }

    var localDate = new Date(utcDate.getTime());
    
    const id = item?.id

    const cardDownloadHandler = () => {
        if (item?.oper_card_link) {
            const link = document.createElement('a');
            link.href = item?.oper_card_link;
            document.body.appendChild(link);
            link.click()
        }
    }

    interface DataType {
        "id": number
        "name": string
        "phone": string
        "time_accept_gbr": string
        "who_pressed_accept": string
        "time_arrive_gbr": string
        "who_pressed_arrive": string
        "time_departure_gbr": string
        "who_pressed_departure": string   
    }
    const columns = [
        {
            title: 'Название ГБР',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
          title: 'Дата принятия',
          dataIndex: 'time_accept_gbr',
          key: 'time_accept_gbr',
        },
        {
          title: 'Кто нажал принятие',
          dataIndex: 'who_pressed_accept',
          key: 'who_pressed_accept',
        },
        {
          title: 'Дата прибытия',
          dataIndex: 'time_arrive_gbr',
          key: 'time_arrive_gbr',
        },
        {
          title: 'Кто нажал прибытие',
          dataIndex: 'who_pressed_arrive',
          key: 'who_pressed_arrive',
        },
        {
          title: 'Дата убытия',
          dataIndex: 'time_departure_gbr',
          key: 'time_departure_gbr',
        },
        {
          title: 'Кто нажал убытие',
          dataIndex: 'who_pressed_departure',
          key: 'who_pressed_departure',
        },
      ];

      const data: DataType[] = item?.gbrs?.map((element: any) => {
        return (
            {
                name: element?.name,
                phone: element?.phone,
                time_accept_gbr: element?.time_accept_gbr && formatIsoToDate(element?.time_accept_gbr) + ` ` + formatIsoToTime(element?.time_accept_gbr),
                who_pressed_accept: element?.who_pressed_accept,
                time_arrive_gbr: element?.time_arrive_gbr && formatIsoToDate(element?.time_arrive_gbr) + ` ` + formatIsoToTime(element?.time_arrive_gbr),
                who_pressed_arrive: element?.who_pressed_arrive,
                time_departure_gbr: element?.time_departure_gbr && formatIsoToDate(element?.time_departure_gbr) + ` ` + formatIsoToTime(element?.time_departure_gbr),
                who_pressed_departure: element?.who_pressed_departure,
            }
        )
    })

    return <Dialog open={open} maxWidth={'md'}>
        <ModalHeader id={id} title={title} closeHandler={closeHandler} />
        <div className={s.body}>
            <div className={s.body__list}>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Дата создания
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.created_at && formatIsoToDate(item?.created_at)}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        ПЦО
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.pco?.name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Тип системы
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.system?.name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Пультовый номер
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.pult || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Наименование объекта
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.object_name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Адрес объекта
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.object_address || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Время сработки
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.time_work ? formatIsoToDate(localDate.toISOString()) + ' ' + formatIsoToTime(localDate.toISOString()) : "Не указано"}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Время приема в обработку
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.time_in ? formatIsoToDate(item?.time_in) + ' ' + formatIsoToTime(item?.time_in) : "Не указано"}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Время вызова ГБР
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.time_call_gbr ? formatIsoToDate(item?.time_call_gbr) + ' ' + formatIsoToTime(item?.time_call_gbr) : "Не указано"}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Реагирование
                    </div>
                    <div className={s.body__list__item__value}>
                        <SimpleCheckbox
                            value={!!item?.is_external}
                            disabled
                            onChange={() => {
                            }}
                        />
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Оператор принял
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.operator_taked || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Тип события
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.event_type?.name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Оператор выслал
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.operator_sent || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Тип тревоги
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.alert_type || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Оператор завершил
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.operator_closed_full_name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Вид тревоги
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.alarm_kind?.name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Администратор
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.chief || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Тип дислокации
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.dislocation_type?.name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Причина дислокации
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.reason?.name || 'Не указано'}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Время завершения тревоги
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.date_close_event ? formatIsoToDate(item?.date_close_event) + ' ' + formatIsoToTime(item?.date_close_event) : "Не указано"}
                    </div>
                </div>
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Время убытия с дислокации
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.time_departure_gbr ? formatIsoToDate(item?.time_departure_gbr) + ' ' + formatIsoToTime(item?.time_departure_gbr) : "Не указано"}
                    </div>
                </div>
                {/* <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                        Состояние
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.external_state || 'Не указано'}
                    </div>
                </div> */}
                {/*
                <div className={s.body__list__item}>
                    <div className={s.body__list__item__title}>
                    Время сработки
                    </div>
                    <div className={s.body__list__item__value}>
                        {item?.time_work || 'Не указано'}
                    </div>
                </div> */}
            </div>
            <div className={s.body__gbrList}>
                {item?.gbrs?.length === 0 ? <> </> :
                    <Table
                        size='large'
                        rowClassName={s.tableRow}
                        columns={columns}
                        dataSource={data}
                        bordered
                                pagination={false}
                            />
            }
                    {/* <div className={s.body__gbrList__title}>
                        Список ГБР
                    </div>
                    <div className={s.body__gbrList__value}>
                        {item?.gbrs?.map((i: { name: string; phone: string; time_arrive_gbr: string; }, idx: number) => {
                            if (idx !== (item?.gbrs?.length - 1)){ 
                                return (i?.name + ' ' + i?.phone + ' ' + (i?.time_arrive_gbr ? formatIsoToDate(i?.time_arrive_gbr) + ' ' + formatIsoToTime(i?.time_arrive_gbr) : 'нет даты') + ", ")
                            }else{
                                return (i?.name + ' ' + i?.phone + ' ' + (i?.time_arrive_gbr ? formatIsoToDate(i?.time_arrive_gbr) + ' ' + formatIsoToTime(i?.time_arrive_gbr) : 'нет даты'))}
                        })}
                    </div> */}
            </div>
            <div className={s.body__list__item} style={{marginTop: '10px'}}>
                <div className={s.body__list__item__title}>
                    Комментарий
                </div>
                <div className={s.body__list__item__value}>
                    {item?.report_comment || 'Не указано'}
                </div>
            </div>
            {
                item?.oper_card_link && 
                <div>
                    <Button onClick={cardDownloadHandler}>
                        Скачать опер карту
                    </Button>
                </div>
            }
        </div>
    </Dialog>
}