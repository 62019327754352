import axios from "axios";

export const paths = {
     baseURL: 'https://apireport.app.neva-online.ru',//прод
    // baseURL: 'https://apireport.neva-online.ru',//test
     socketPath: 'https://apireport.app.neva-online.ru',//прод
    // socketPath: 'https://apireport.neva-online.ru',//test
    baseURLOpen: 'api/data/open',
    baseURLClose: 'api/v1/',
    baseURLCloseReport: 'api/report/',
    baseURLCloseFiles: 'api/file',

    //Общее
    getWorkersWithGroups: '/sel_users',

    //количество заявок разного типа
    getApplicationsCounts: '/sel_application_counts',

    //Авторизация
    authorization: 'loginReport',

    refreshToken: 'refresh_token',

    //Заявки
    getApplicationList: 'sel_tasks',
    getApplicationInfo: 'sel_task',
    iudApplication: 'iud_task',
    restoreApplication: 'restore_task',
    getAppStatuses: 'sel_task_statuses',
    getTaskHistory: 'sel_task_history',
    getTaskActions: 'sel_events_ticket',

    //Новая заявка
    getTaskSamples: '/sel_task_samples',
    getPeriods: '/sel_task_periodicity',
    getSystems: '/sel_pults',
    iudSystem: '/iud_system',
    getDevices: '/sel_devices',
    getWorkersTree: '/sel_workers_with_groups',
    getClientInfo: '/sel_clients',
    getTags: '/sel_tags',
    saveFile: 'SaveFileByte',


    //Уведомления
    socketConnection: '/Notification',
    getMyNotifications: '/sel_notifications',
    checkedNotifications: '/check_new_notifications',

    //Сотрудники
    getWorkersListOrItem: 'sel_users',
    iudGroup: 'iud_contractor_group',
    getGroups: 'sel_contractor_groups',
    addWorkersToGroup: 'appoint_contractor_group',
    iudWorker: 'iud_user',


    //Справочники
    getDirectoryGroups: 'sel_directory_groups',
    getDirectoryCategories: 'sel_task_categories',
    getDirectoryRoles: 'sel_contractor_roles',
    getPriority: 'sel_categories_priority',
    iudCategory: 'iud_task_categories',
    iudRole: 'iud_contractor_role',
    getTaskComments: 'sel_task_comments',
    sendComment: 'send_task_comment',
    getMarks: 'sel_tags',
    iudDevice: 'iud_device',
    iudMark: 'iud_tag',
    getColors: 'sel_colors',
    getCelebrationDays: 'sel_workdays',
    iudCelebrationDay: 'iud_workday',
    getTypes: 'sel_types',
    calculateTaskDate: 'calculate_task_date',

    //Информация о пользователе
    getMyInfo: 'sel_user',

    //формвью
    getFormview: 'sel_formviews',

    //Полный список ролей1
    getFullRoleList: 'sel_roles',

    //ФИО
    getFios: 'get-users',

    //отчеты
    getGoCountRaport: 'get-departure-infos',
    getGoExternalObject: 'get-external-departure-infos',
    getGoCountGroup: 'get-group-work-day',
    getGoRaport: 'get-daily-raport-infos',
    getNewGoReports: 'departure-report',
    getDislocations: 'get-dislocation-infos',
    getRaports: 'get-raports',
    getRaportsVK: 'get-my-team-pressed-button-gbr',
    getRaportsMonth: 'selection-group-by-month',
    getOperatorRaport: 'operator-report',
    getRaportsMonthNew: 'selection-group-by-month-filtered-by-territory',
    getRaportsAlert: 'alarm-counting',
    getExternalDepartureGeneralCountReportLoading: 'external-departure-general-count-report',
    journalActions: 'journal-actions',
    getGBRS: 'get-gbrs',
    getPCO: 'get-pco',
    getEventTypes: 'get-event-types',
    getAlarmKindTypes: 'get-alarm-kinds',
    getReportXLSX: 'ShowReportBase64',
    getCaesarReport: 'get-cezar-departures-count',
    getChopReport: 'get-all-chop',
    sendChopReport: 'get-departure-chop-count',
    addTerritory: 'add-territory',
    getTerritory: 'get-territories',
    updateTerritory: 'update-territory',
    deleteTerritory: 'delete-territory',


    //Поиск по рапортам

    getAddressesOfRaports : 'get-objects-adress',
    getObjectsOfRaports : 'get-objects-names',
    getPultsOfRaports : 'get-pults',
}